import React, { useState, useEffect } from 'react';
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { retrieveLaunchParams } from '@telegram-apps/sdk';

const Logo = () => (
  <svg className="w-full h-full" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" fill="none" stroke="currentColor" strokeWidth="2"/>
    <line x1="0" y1="0" x2="60" y2="60" stroke="currentColor" strokeWidth="2"/>
    <line x1="60" y1="0" x2="0" y2="60" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

const LoadingDots = () => (
  <div className="flex justify-center items-center space-x-1">
    <div className="w-1.5 h-1.5 bg-black rounded-full animate-pulse"></div>
    <div className="w-1.5 h-1.5 bg-black rounded-full animate-pulse delay-75"></div>
    <div className="w-1.5 h-1.5 bg-black rounded-full animate-pulse delay-150"></div>
  </div>
);

const LoadingScreen = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
    <Logo />
    <h1 className="text-2xl font-bold mt-4 mb-8">WEPLE</h1>
    <LoadingDots />
  </div>
);

const ErrorScreen = ({ message }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
    <Logo />
    <h1 className="text-2xl font-bold mt-4 mb-8">WEPLE</h1>
    <p className="text-red-500 text-center">{message}</p>
    <button 
      onClick={() => window.location.reload()} 
      className="mt-4 px-4 py-2 bg-white text-black rounded-full font-bold text-sm"
    >
      다시 시도
    </button>
  </div>
);

const Card = ({ title, description, buttonContent, onClick, disabled }) => {
  return (
    <div className="bg-gray-800 rounded-lg p-4 mb-4 flex justify-between items-center">
      <div>
        <h2 className="text-white font-bold text-lg">{title}</h2>
        <p className="text-gray-400 text-sm">{description}</p>
      </div>
      <button 
        className={`px-4 py-2 rounded-full font-bold text-sm transition-colors duration-300 
          ${disabled ? 'bg-green-500 text-white' : 'bg-white text-black'}
          disabled:opacity-70 disabled:cursor-not-allowed min-w-[80px] h-9 flex items-center justify-center`}
        onClick={onClick}
        disabled={disabled}
      >
        {buttonContent}
      </button>
    </div>
  );
};

const App = () => {
  const [tonConnectUI] = useTonConnectUI();
  const userAddress = useTonAddress();
  const [serverResponse, setServerResponse] = useState(null);
  const { initDataRaw } = retrieveLaunchParams();
  const [token, setToken] = useState("");
  const [x_url, setXURL] = useState("");
  const [isTwitterConnected, setIsTwitterConnected] = useState(false);
  const [isCheckingTwitter, setIsCheckingTwitter] = useState(false);
  const [followState, setFollowState] = useState('initial');
  const [visitState, setVisitState] = useState('initial');
  const [isFollowing, setIsFollowing] = useState(false);
  const [error, setError] = useState(null);

  const authenticate = async () => {
    try {
      const response = await fetch('https://api.goarosa.com/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ initDataRaw }),
      });

      if (response.ok) {
        const data = await response.json();
        setToken(data.token);
        setXURL(`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ZEtRV1NFOE9TVi0wZTBIR3BNMkw6MTpjaQ&redirect_uri=${encodeURIComponent("https://api.goarosa.com/callback")}&scope=tweet.read%20users.read%20follows.write%20offline.access&code_challenge=challenge&code_challenge_method=plain&state=${encodeURIComponent(data.token)}`)
        localStorage.setItem('MISSW_USER', data.token);
      } else {
        console.error('Authentication failed');
        setError('인증에 실패했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setError('인증 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  useEffect(() => {
    if (token.length === 0) {
      authenticate();
    }
  }, []); // 컴포넌트 마운트 시 한 번만 실행

  useEffect(() => {
    if (token.length > 0) {
      console.log(`Token updated: ${token}`);
      // 토큰이 설정되면 여기서 추가 작업을 수행할 수 있습니다.
      if (userAddress) {
        sendAddressToServer(userAddress, token);
      }
    }
  }, [token]); // token이 변경될 때마다 실행

  const handleConnectTwitter = async () => {
    const connected = await checkTwitterConnection();
    if (!connected) {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(x_url);
      } else {
        window.open(x_url, '_blank');
      }
  
      setIsCheckingTwitter(true);
      const checkInterval = setInterval(() => {
        checkTwitterConnection().then((connected) => {
          if (connected) {
            setIsTwitterConnected(true);
            setIsCheckingTwitter(false);
            clearInterval(checkInterval);
          }
        });
      }, 5000); // Check every 5 seconds
  
      // Clear interval after 2 minutes (120000 ms) to prevent infinite checking
      setTimeout(() => {
        clearInterval(checkInterval);
        setIsCheckingTwitter(false);
      }, 30000);
    }
  };

  const checkTwitterConnection = async () => {
    try {
      const response = await fetch('https://api.goarosa.com/api/check-twitter-connection', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setIsTwitterConnected(data.connected);
      return data.connected;
    } catch (error) {
      console.error('Error checking Twitter connection:', error);
      return false;
    }
  };
  
  const checkFollowStatus = async () => {
    try {
      const response = await fetch('https://api.goarosa.com/api/check-follow', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      const data = await response.json();
      if (data.following) {
        setIsFollowing(true);
        setFollowState('done');
      } else {
        setIsFollowing(false);
        setFollowState('initial');
      }
    } catch (error) {
      console.error('Error checking follow status:', error);
      setFollowState('initial');
    }
  };

  const sendAddressToServer = async (address, currentToken) => {
    try {
      const response = await fetch('https://api.goarosa.com/api/wallet-connect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
        body: JSON.stringify({ address }),
      });
      const data = await response.json();
      setServerResponse(data.message);
    } catch (error) {
      console.error('Error sending address to server:', error);
      setServerResponse('Failed to connect to server');
      setError('Failed to connect to server');
    }
  };

  const getTwitterButtonContent = () => {
    if (isTwitterConnected) return "DONE";
    if (isCheckingTwitter) return <LoadingDots />;
    return "CONNECT";
  };

  const handleFollow = () => {
    setFollowState('loading');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://x.com/misswproject");
    } else {
      window.open("https://x.com/misswproject", '_blank');
    }
    
    // 첫 번째 확인은 10초 후에 실행
    setTimeout(() => {
      checkFollowStatus();
    }, 10000);
  };

  const handleVisit = () => {
    setVisitState('loading');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink("https://missw.app/");
    } else {
      window.open("https://missw.app/", '_blank');
    }
    setTimeout(() => {
      setVisitState('done');
    }, 100);
  };

  if (error) {
    return <ErrorScreen message={error} />;
  }

  if (token.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <div className="bg-black text-white min-h-screen p-5 flex flex-col items-center">
      <div className="mb-8 text-center">
        <div className="w-16 h-16 border-2 border-white mb-2 mx-auto">
          <Logo />
        </div>
        <h1 className="text-2xl font-bold">WEPLE</h1>
      </div>
      <div className="w-full max-w-lg mb-4">
        <TonConnectButton />
        {/* {userAddress && (
          <p className="text-sm text-gray-400 mt-2">Connected: {userAddress.slice(0, 6)}...{userAddress.slice(-4)}</p>
        )} */}
        {/* {serverResponse && (
          <p className="text-sm text-green-500 mt-2">{serverResponse}</p>
        )} */}
      </div>
      <div className="w-full max-w-lg">
        <Card 
          title="CONNECT X.COM"
          description="Connect X.com Account"
          buttonContent={getTwitterButtonContent()}
          onClick={handleConnectTwitter}
          disabled={isTwitterConnected}
        />
        {isTwitterConnected && (
          <Card 
            title="FOLLOW X.COM"
            description="Follow Miss W Project"
            buttonContent={
              followState === 'loading' ? <LoadingDots /> : 
              (isFollowing ? "DONE" : "FOLLOW")
            }
            onClick={handleFollow}
            disabled={isFollowing}
          />
        )}
        <Card 
          title="VISIT MISSW.APP"
          description="Visit Miss W Project Web Site"
          buttonContent={visitState === 'loading' ? <LoadingDots /> : (visitState === 'done' ? "DONE" : "VISIT")}
          onClick={handleVisit}
          disabled={visitState === 'done'}
        />
      </div>
    </div>
  );
};

export default App;